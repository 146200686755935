


















































































import { Component, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, confirmed } from 'vee-validate/dist/rules'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import NotificationMixin from '@/mixins/notificationMixin'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver
  }
})
export default class RecoverAccessNew extends Mixins(NotificationMixin) {
  done = true
  isTokenValid = true
  passwordResetSuccess = false
  form: Record<string, any> = {
    password: '',
    passwordConfirmation: '',
    errors: {
      password: ''
    }
  }

  validations () {
    extend('required', {
      ...required,
      message: 'The {_field_} field is required.'
    })

    extend('confirmed', {
      ...confirmed,
      message: 'The {_field_} field does not match.'
    })
  }

  created () {
    this.validations()

    axios.get(`user/password/recover/${this.$route.params.token}`)
      .then(() => {
        this.isTokenValid = true
      })
      .catch(err => {
        const response = err.response.data
        this.isTokenValid = false
        if (response.status === 400) {
          this.setNotification('error', response.error)
        }
      })
  }

  submitForm () {
    this.done = false
    const form = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      recover_token: this.$route.params.token,
      password: this.form.password
    }
    axios.post(`user/password/recover/${this.$route.params.token}`, form)
      .then(response => {
        this.setNotification('success', response.data.data)
        this.passwordResetSuccess = true
      })
      .catch((err) => {
        const response = err.response

        response.error ? this.setNotification('error', response.error) : this.setNotification('error', 'Ocorreu um erro ao fazer a troca de senha.')
        const errors = response.data.errors
        if (errors) {
          this.setNotification('error', 'Os dados fornecidos não são válidos, verifique o formulário.')
          if (response.data.errors) {
            const errors = response.data.errors

            Object.entries(errors).map(([key, value]: [string, any]) => {
              this.form.errors[key] = value[0]
            })
          }
        }
      })
      .finally(() => { this.done = true })
  }

  get loginRedirectUrl () {
    const { eventId, voucherId } = camelCaseKeys(this.$route.query, { deep: true })
    if (voucherId && eventId) {
      return { name: 'EventVoucher', params: { voucherId, eventId } }
    } else if (eventId) {
      return { name: 'EventBuyTicketsAccess', params: { eventId } }
    } else {
      return { name: 'Login' }
    }
  }
}
